table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}

tr:nth-child(even) {
  background-color: var(--theme-secondary);
}

tr:nth-child(odd) {
  background-color: #0000001f;
}

tr {
  text-align: center;
  font-size: 12px;
  padding: 12px;
}

tr.header-row {
  background-color: var(--theme-dark);
  color: '#ffffff'
}

tr:hover {
  background-color: var(--theme-accent);
  cursor: pointer;
}

th, td .table-header {
  background-color: #282829;
  text-align: center;
}

th {
  padding: 7px;
  color: #fff;
  font-size: 14px;
}

td {
  border: none;
  padding: 7px;
}

.grid-container {
  overflow: scroll;
}

.grid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #0000001f;
}

.row-hover:hover {
  background-color: var(--theme-accent);
  cursor: pointer;
}

.header-row {
  background-color: var(--theme-dark);
}

.alt-row {
  background-color: var(--theme-secondary);
}

.grid-cell {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 2.3em;
  overflow: hidden;
}

.grid-header {
  padding: 0;
  margin: 0;
  font-weight: bold;
  color: var(--theme-offwhite);
}

.grid-text {
  padding: 0;
  margin: 0;
}