/* TEXT */

@font-face {
  font-family: "DIN Next LT Pro Family";
  src: url('./fonts/DINNextLTPro-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: "DIN Next LT Pro Family";
  src: url('./fonts/DINNextLTPro-BoldCondensed.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: "DIN Next LT Pro Family";
  src: url('./fonts/DINNextLTPro-Medium.otf') format('opentype');
  font-weight: 600;
}
@font-face {
  font-family: "DIN Next LT Pro Family";
  src: url('./fonts/DINNextLTPro-Regular.otf') format('opentype');
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px !important;
  padding: 0px !important;
}

h4 {
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.page-header {
  font-weight: 700;
  font-size: 36px;
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

.link-tag{
  color: var(--theme-accent);
  text-decoration: underline;
}

.link-tag:hover{
  color: var(--theme-accent-darker);
  cursor: pointer;
}

.err-text {
  color: red;
  font-style: italic;
  margin: 0;
  padding: 0;
  text-align: left;
}

.dialog-header {
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.fd-instruction{
  color: #444;
  font-size: 26px;
  margin-bottom: 30px;
}

.important-status{
  font-weight: 600;
  font-size: 22px;
  color: var(--theme-accent);
}

.header-profile-text{
  font-weight: 600;
  font-size: 18px;
  color: var(--theme-offwhite);
  transition: all 0.6s;
}

.header-profile-text:hover{
  color: var(--theme-accent);
  cursor: pointer;
  text-decoration: none;
}

.item-header{
  font-size: 22px;
}

.item-detail{
  font-weight: 400;
  font-size: 14px;
}

.menu-varietal{
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
}

.question {
  font-size: 18px;
  font-weight: 900;
}