:root {
  --spinner-width: 7em;
  --spinner-width-small: 2em;
  --spinner-color1: #008744;
  --spinner-color2: #0057e7;
  --spinner-color3: #d62d20;
  --spinner-color4: #ffa700;
  --spinner-empty-color: #eee;
  
  --theme-offwhite: #f8f7ec;
  --theme-secondary: #758d87;
  --theme-accent: #ff595a;
  --theme-accent-darker: #f82a2a;
  --theme-dark: #24272a;
}

body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'DIN Next LT Pro Family', 'Helvetica Neue', sans-serif !important;
}

.main {
  background-color: var(--theme-offwhite);
  overflow: auto;
}


/* TEMP */
.temp-offset-for-input {
  margin-top: 10px !important;
}

.temp-checkbox {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-color: var(--theme-offwhite);
}

.italic-text {
  padding: 0;
  margin: 0;
  font-style: italic;
}

.temp-short-button {
  margin: 0 !important;
  padding: 10px !important;
  max-height: 3em !important;
  max-width: 300px !important;
}
.menu-item{
  padding: 10px;
  border: 1px solid var(--theme-dark);
  border-top: 0;
  transition: all 0.5s;
  font-size: 1.2rem;
  font-weight: 600;
}
.menu-item:hover{
  background-color: var(--theme-secondary);
  color: var(--theme-offwhite);
  cursor: pointer;
}


/* COMPONENTS */
.sticky-header {
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  margin: 0px -15px;
  background-color: var(--theme-dark);
  color: black;
  z-index: 1;
}

.base-textarea {
  min-height: 6.6em !important;
}

.reasonably-sized-menu {
  max-height: 250px !important;
  overflow: auto !important;
}

/* CONTAINERS */
.content-view {
  min-height: 85vh;
  margin-top: 60px;
}

.box-of-cards{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.item-card{
  border: 1px solid var(--theme-accent);
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  width: 300px;
  max-width: 50%;
  transition: all 0.6s;
}

.item-card:hover{
  box-shadow: 0px 8px 16px 10px #00000033;
}

.dark-view{
  background-color: var(--theme-dark);
}

.centered-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.content-card {
  background-color: var(--theme-offwhite);
  padding: 2em;
  border: 1px solid var(--theme-dark);
}

.singular-card {
  margin-top: 20px;
  width: 700px;
}

.centered-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}


.fd-container {
  background-color: var(--theme-offwhite);
}

.fd-area {
  padding: 2em;
  border: 3px dashed #aaa;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
  height: 100px;
}

.fd-active {
  border: 3px dashed #333;
}

.filter-group {
  border: 1px solid black;
  border-radius: 10px;
  padding: 15px;
  margin: 20px 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}



@media only screen and (max-width: 600px) {
  .content-view {
    min-width: 80vw;
    /* background-color: rgb(255, 255, 197); */
  }

  .singular-card {
    margin: 0px;
    box-shadow: none;
    border: none;
  }
}


/* INPUTS */
.base-input{
  margin: 10px 0px;
  width: 100% !important;
}
.input-err {
  border: 1px solid #ff595a !important;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before {
  height: 16px;
  width: 16px;
  border: 1px solid;
  left: 0px;
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"]+label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked+label::after {
  content: "";
}



/* SPINNER STYLE */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: inline-block;
  width: var(--spinner-width);
  height: var(--spinner-width);
}

.circular {
  animation: rotate 2s linear infinite;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {

  100%,
  0% {
    stroke: var(--spinner-color3);
  }

  40% {
    stroke: var(--spinner-color2);
  }

  66% {
    stroke: var(--spinner-color1);
  }

  80%,
  90% {
    stroke: var(--spinner-color4);
  }
}