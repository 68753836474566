.menu-widget{
  /* position: absolute; */
  /* right: 40px; */
  position: absolute;
  right: 40px;
  top: 18px;
  z-index: 3 !important;
  box-shadow: 0px 8px 16px 10px #00000033;
}
.menu-button{
  background-color: var(--theme-accent);
  width: 250px;
  outline: 0px !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 40px;
  top: 50px;
  text-align: left;
  background-color: #fff;
  width: 220px;
  overflow: auto;
  z-index: 3 !important;
  border: 1px solid #9e9e9e;
}

.nav-link {
  background-color: var(--theme-offwhite);
  color: var(--theme-secondary);
  padding: 12px 30px;
  width: 250px;
  text-decoration: none;
  display: block;
  font-size: 18px;
  font-weight: 700;
  border-left: 1px solid var(--theme-dark);
  border-right: 1px solid var(--theme-dark);
  border-bottom: 1px solid var(--theme-dark);
  transition: background-color 0.4s, color 1.2s;
}

.nav-link:hover {
  background-color: var(--theme-accent);
  color: var(--theme-offwhite);
  text-decoration: none;
  cursor: pointer;
}

.logout-link{
  color: red;
}

.logout-link:hover{
  color: #fff;
}

.show-dropdown {
  display: block;
}

.brgr-wrapper {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .nav-link {
    padding: 1em 1.6em !important;
  }
}