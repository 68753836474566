.base-button {
    background-color: var(--theme-secondary);
    outline: none;
    border: 0px;
    color: var(--theme-offwhite);
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    height: 50px;
    transition: background-color 0.4s, color 1s;
}

.base-button:hover,
.base-buttonr:focus,
.base-button:active {
    background-color: var(--theme-accent-darker);
}

.base-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.add-button {
    width: 150px;
    padding: 3px 15px;
}

.red-button {
    background-color: #ff0000 !important;
}

.reasonably-sized-button {
    max-width: 350px !important;
}

.heading-button {
    max-width: 100px;
    padding: 5px 10px;
    height: 40px;
}

.move-button {
    background-color: var(--theme-accent);
    border-radius: 50%;
    height: 30px;
    width: 30px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
}

.move-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.arrow-img{
    height: 18px;
    width: 18px;
    resize: cover;
    margin: 0;
    padding: 0;
}